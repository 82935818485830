import React from "react"
import { Link } from "gatsby"
import SocialLinks from "../constants/socialLinks"
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <SocialLinks styleClass="footer-icons" />
        <p>
          <p className="center-element" rel="nofollow">
            &copy;{new Date().getFullYear()} TheDigitalSpecs.com. All rights
            reserved.{" "}
          </p>
          <div className="center-element">
            <Link to="/about" className="page-link" rel="nofollow">
              About
            </Link>{" "}
            |{" "}
            <Link to="/contact" className="page-link" rel="nofollow">
              Contact Us
            </Link>{" "}
            |{" "}
            <Link to="/affiliate" className="page-link" rel="nofollow">
              Affiliate Disclosure
            </Link>
            |{" "}
            <Link to="/privacy-policy" className="page-link" rel="nofollow">
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link to="/terms" className="page-link" rel="nofollow">
              Terms & Conditions
            </Link>
          </div>
          <div
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            rel="nofollow"
          >
            As an Amazon and Other Associate The TheDigitalSpecs earn from
            qualifying purchases or other refferel.
            <Link to="/" className="page-link" rel="nofollow">
              www.thedigitalspecs.com
            </Link>
            is an affiliate program designed to provide a means for sites to
            earn advertising fees by advertising and linking to Amazon.com or
            other Affiliated Link. The TheDigitalSpecs is reader-supported. When
            you buy or register through links on our site, we may earn an
            affiliate commission
          </div>
        </p>
      </div>
    </footer>
  )
}

export default Footer
