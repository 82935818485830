import React from "react"
import { Link } from "gatsby"
const Links = ({ styleClass, children }) => {
  return (
    <ul className={styleClass}>
      <li>
        <Link to="/marketingtools" className="page-link">
          Marketing Tools
        </Link>
      </li>
      <li>
        <Link to="/apps" className="page-link">
          Apps
        </Link>
      </li>
      <li>
        <Link to="/electronics" className="page-link">
          Electronics
        </Link>
      </li>
      <li>
        <Link to="/best-drones" className="page-link">
          Drones
        </Link>
      </li>

      <li>
        <Link to="/appliances" className="page-link">
          Appliances
        </Link>
      </li>

      <li>
        <Link to="/newsletter" className="page-link">
          Subscribe
        </Link>
      </li>
      {/* <li>
        <Link to="/apps" className="page-link">
          Apps
        </Link>
        {children}
      </li>
      <li>
        <Link to="/newsletter" className="page-link">
          Newsletter
        </Link>
      </li>
      <li>
        <Link to="/apps" className="page-link">
          Apps
        </Link>
        {children}
      </li>
      <li>
        <Link to="/newsletter" className="page-link">
          Newsletter
        </Link>
      </li> */}
      {/* <li>
        <Link to="/post" className="page-link">
          Post
        </Link>
      </li> */}
    </ul>
  )
}

export default Links
