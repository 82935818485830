import React from "react"
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa"
const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li>
        <a href="https://web.whatsapp.com/" target="_blank" rel="noreferrer">
          <FaWhatsapp className="social-icon whatsapp-icon"></FaWhatsapp>
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UCYwetySh61Ymfo8dNJ66RSg"
          target="_blank"
          rel="noreferrer"
        >
          <FaYoutube className="social-icon youtube-icon"></FaYoutube>
        </a>
      </li>

      <li>
        <a
          href="https://www.facebook.com/The-Digital-Specs-107359804338938"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebookSquare className="social-icon facebook-icon"></FaFacebookSquare>
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagramSquare className="social-icon dribble-icon"></FaInstagramSquare>
        </a>
      </li>
      <li>
        <a href="https://twitter.com" target="_blank" rel="noreferrer">
          <FaTwitterSquare className="social-icon twitter-icon"></FaTwitterSquare>
        </a>
      </li>
    </ul>
  )
}
export default SocialLinks
