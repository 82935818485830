import React from "react"
import Layout from "../components/Layout"
import { Form, Col, Row, Button } from "react-bootstrap"
const Contact = () => {
  return (
    <Layout>
      <section className="newsletter-page">
        <div className="page-center">
          <h3 style={{ paddingTop: "10rem" }}>Contact Us</h3>
          <h4>
            If you have any questions regarding this policy, or your dealings
            with our website, please contact us here:
          </h4>
          <div
            style={{
              alignItems: "center",
              width: "80%",
              paddingBottom: "10rem",
              paddingLeft: "1rem",
            }}
          >
            <Form
              className="page-center"
              name="contact-form"
              method="POST"
              data-netlify-honeypot="bot-field"
              data-netlify="true"
              action="/success"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact-form" />
              <Form.Group as={Row} controlId="formHorizontalName">
                <Form.Label column>Your Name (required)</Form.Label>
                <Row>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    required
                  />
                </Row>
              </Form.Group>
              <Form.Row>
                <Form.Group as={Row} controlId="formHorizontalEmail">
                  <Form.Label column>Your Email (required)</Form.Label>
                  <Row m={{ span: 10, offset: 2 }}>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                  </Row>
                </Form.Group>
              </Form.Row>

              <Form.Group as={Row}>
                <Row width={500}>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="subject"
                      rows={2}
                      required
                    />
                  </Form.Group>
                </Row>
              </Form.Group>

              <Form.Group as={Row}>
                <Row width={500}>
                  <Form.Group controlId="exampleForm.ControlTextarea2">
                    <Form.Label>Your Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      rows={8}
                      required
                    />
                  </Form.Group>
                </Row>
              </Form.Group>

              <Form.Group as={Row}>
                <Col sm={{ span: 10, offset: 2 }}>
                  <Button type="submit">Send</Button>
                </Col>
              </Form.Group>
            </Form>
          </div>

          {/* <Form
            className="contact-form"
            name="contact"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/success"
          >
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" />
            </Form.Group>

            <button type="submit" className="btn form-control submit-btn">
              Send
            </button>
          </Form> */}
          {/* <form
            className="contact-form"
            name="contact"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/success"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <h4>To TheDigitalSpecs</h4>
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your name"
                className="form-control "
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Your email"
              />
            </div>
            <div>
              <div>
                <input
                  type="textarea"
                  name="subject"
                  placeholder="Subject"
                  className="form-control "
                />
              </div>
            </div>

            <button type="submit" className="btn form-control submit-btn">
              Send
            </button>
          </form> */}
        </div>
      </section>
    </Layout>
  )
}

export default Contact
